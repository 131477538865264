/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';
import {
    object, shape, arrayOf, string, array, number,
    bool,
} from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import DeliveryTable from '../../../DeliveryTable/DeliveryTable';
import CountDownTimerBanner from '../CountDownTimerBanner/CountDownTimerBanner';
import {
    getPresentationFamily, getFeatureFlags, getCurrentBrandId,
} from '../../../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import transformRenderer, { transformCharacters } from '../../../../../../../../../helpers/markdown/characterTransformer';
import { getOperatingSystem } from '../../../../../../../../../../state/ducks/App/ducks/Config/Config-Helpers';
import { RenderChatAsLink } from '../../../../../../../../MultiBrandChatController/AigoChatWidgetWrapperV2/popOpenChat';

const useStyles = makeStyles((theme) => ({
    imocWrapper: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
        padding: '10px 0',
        margin: '0 auto',
        border: '0',
        alignItems: 'center',
        [theme.breakpoints.up(769)]: {
            flexDirection: 'row',
            margin: '0 auto',
            padding: '15px 0 5px 0',
        },
    },
    imocWrapperBgColor: ({ imocBackgroundColor }) => ({
        backgroundColor: imocBackgroundColor,
        padding: '25px 5px 25px 5px',
        marginBottom: '10px',
    }),
    imocWrapperFlower: {
        flexDirection: 'column',
        [theme.breakpoints.up(835)]: {
            flexDirection: 'row',
        },
    },
    centerHero: {
        justifyContent: 'center',
        display: 'block',
    },
    TopimocWrapper: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        margin: '25px auto',
        border: '0',
        borderBottom: 'solid 1px #ccc',
        paddingBottom: '10px',
    },
    header: ({ headerColor }) => ({
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.palette?.text?.fontSize24 || '24px',
        whiteSpace: 'nowrap',
        color: headerColor || theme.palette.colorPrimary,
        paddingRight: '40px',
        margin: '0',
        lineHeight: '40px',
    }),
    headerWrapper: {
        alignSelf: 'flex-start',
        [theme.breakpoints.up(835)]: {
            alignSelf: 'center',
        },
    },
    headerImocFood: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.palette?.text?.fontSize24 || '24px',
        whiteSpace: 'nowrap',
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        paddingRight: '40px',
        margin: '0',
        lineHeight: '40px',
        alignSelf: 'flex-start',
        [theme?.breakpoints?.up(769)]: {
            alignSelf: 'center',
            margin: '0 40px 0 0',
        },
        [theme?.breakpoints?.down(769)]: {
            alignSelf: 'center',
            margin: '0 0 0 16px',
            fontSize: theme.typography?.fontSize || '16px',
        },
    },
    noImocCopyStyling: {
        paddingRight: 0,
        [theme.breakpoints.up(769)]: {
            borderRight: 'none',
            margin: 0,
            fontSize: theme.palette?.text?.fontSize24 || '24px',
            textAlign: 'center',
        },
    },
    headerTop: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.palette?.text?.fontSize24 || '24px',
        borderRight: `1px solid ${theme.palette.colorNeutral60}`,
        whiteSpace: 'nowrap',
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        paddingRight: '40px',
        paddingLeft: '110px',
        margin: '0 40px 0 0',
        lineHeight: '40px',
        letterSpacing: '1.2px',
        alignSelf: 'center',
    },
    imocCopyContainer: ({ imocCopyColor }) => ({
        [theme.breakpoints.up(835)]: {
            borderLeft: `1px solid ${theme.palette.colorNeutral60}`,
            paddingLeft: '40px',
        },
        display: 'block',
        width: '100%',
        color: imocCopyColor || theme.palette.common.black,
    }),
    topImocCopyContainer: ({ imocCopyColor }) => ({
        display: 'block',
        width: '66%',
        textAlign: 'left',
        paddingLeft: '2%',
        paddingRight: '5%',
        fontSize: theme.palette?.text?.fontSize23 || '23px',
        fontFamily: 'latoregular',
        color: imocCopyColor || theme.palette.common.black,
        [theme?.breakpoints?.up(769)]: {
            borderLeft: `1px solid ${theme.palette.colorNeutral60}`,
        },
        [theme?.breakpoints?.down(769)]: {
            width: '100%',
            fontSize: theme.palette?.text?.fontSize12 || '12px',
        },
    }),
    copy: {
        display: 'inline',
        '& p': {
            margin: 0,
            lineHeight: '23px',
            fontSize: theme.palette?.text?.fontSize14 || '14px',
            display: 'inline',
        },
        '& h2, & h3, & h4, & h5, & h6': {
            margin: 0,
        },
        '& h2': {
            fontWeight: 'normal',
            fontSize: theme.palette?.text?.fontSize20 || '20px',
        },
        '& h3': {
            fontWeight: 'normal',
            fontSize: theme.palette?.text?.fontSize20 || '20px',
        },
        fontFamily: theme.typography.fontFamily,
        '& a': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
    },
    imocCopy: {
        display: 'inline',
        '& p': {
            margin: 0,
            lineHeight: '23px',
            fontSize: theme.palette?.text?.fontSize18 || '18px',
            display: 'inline',
            [theme?.breakpoints?.down(769)]: {
                margin: '0',
                fontSize: theme.palette?.text?.fontSize15 || '15px',
                lineHeight: '18px',
            },
        },
        '& h2, & h3, & h4, & h5, & h6': {
            margin: 0,
        },
        '& h2': {
            fontWeight: 'normal',
            fontSize: theme.palette?.text?.fontSize20 || '20px',
        },
        '& h3': {
            fontWeight: 'normal',
            fontSize: theme.palette?.text?.fontSize20 || '20px',
        },
        fontFamily: theme.typography.fontFamily,
        '& a': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
    },
    readMoreCopy: {
        '& p:not(:last-child)': {
            marginBottom: '10px',
            display: 'block',
        },
    },
    readMore: {
        display: 'inline',
        marginLeft: '4px',
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        fontSize: theme.palette?.text?.fontSize14 || '14px',
        textDecoration: 'underline',
        cursor: 'pointer',
        border: 'none',
        padding: 0,
        backgroundColor: '#FFF',
        '&:focus': {
            outline: 0,
        },
    },
    displayNone: {
        display: 'none',
    },
    imgLogo: {
        display: 'block',
        maxHeight: '90px',
        padding: '10px 40px 10px 40px',
        margin: '0 40px 0 0',
    },
    offer: {
        position: 'absolute',
        fontSize: theme.palette?.text?.fontSize12 || '12px',
        fontStyle: 'italic',
        bottom: '-15px',
        right: '5px',
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
    },
    doubleOfferContainer: {
        display: 'flex',
        width: '100%',
    },
    doubleOffer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        textAlign: 'center',
        borderRight: `1px solid ${theme.palette.colorNeutral60}`,
        '& > h2, & > h3, & > p, & > *': {
            lineHeight: 1.3,
            margin: 0,
        },
        '&::after': {
            transform: 'translate(0,-50%)',
            content: '"OR"',
            fontSize: theme.palette?.text?.fontSize14 || '14px',
            color: '#000',
            position: 'absolute',
            top: '50%',
            right: '-11px',
            backgroundColor: '#fff',
            padding: '5px 0',
        },
        '&:last-child': {
            borderRight: 'none',
            '&::after': {
                display: 'none',
                content: '""',
            },
        },
    },
    imocBorderNone: {
        border: 'none',
    },
    subtextHeader: {
        '& h2, & h3, & h4, & h5, & h6, & p': {
            margin: 0,
            fontSize: theme.palette?.text?.fontSize18 || '18px',
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
            fontFamily: theme.typography.fontFamily,

        },
    },
    bgsImocContainer: {
        borderLeft: 'none',
    },
    imocOfferContainer: {
        paddingBottom: '15px',
        [theme.breakpoints.down(769)]: {
            paddingBottom: '10px',
        },
    },
    desktopImocContainer: {
        width: '100%',
    },
    h1: {
        minWidth: 'fit-content',
        '& h1': {
            maxWidth: '520px',
            whiteSpace: 'normal',
            [theme.breakpoints.down(1024)]: {
                lineHeight: '1.7rem',
                maxWidth: '40vw',
            },
            [theme.breakpoints.down(836)]: {
                lineHeight: '2rem',
                maxWidth: '98vw',
            },
        },
    },
}));

const DesktopTabletImoc = ({
    imocBlock,
    brand,
    presentationFamily,
    samedayCutOff,
    isSameDayCollectionV2,
}) => {
    const [isReadMoreVisible, setIsReadMoreVisible] = useState(false);
    const handleClick = () => {
        setIsReadMoreVisible(!isReadMoreVisible);
    };
    const operatingSystem = getOperatingSystem().toLowerCase();
    const currentBrand = useSelector((state) => getCurrentBrandId(state));
    const featureFlags = useSelector((state) => getFeatureFlags(state));
    const customerData = useSelector((state) => state?.member);
    // if no IMOC block in CMS render dummy div for spacing
    if (!imocBlock) { return <div style={{ height: '15px' }} />; }

    let imocImage;
    let imocOfferSection;
    let layout;
    let header;
    let headerColor = '';
    let imocCopy;
    let imocCopyColor = '';
    let imocReadMoreCopy;
    let subtextHeader;
    let imocShowSameDay;
    let imocBackgroundColor;
    let countDownColors;
    let languageAttribute;

    // font size is on IMOCs from 'Standard Collections', applied to copy
    let fontSize;

    imocBlock.forEach((block) => {
        // api call will return web_imoc + mobile_imoc || or just web_imoc
        // mobile imoc is for native mobile app ( that doesnt exsist yet )
        if (block.name?.toLowerCase() !== 'mobile imoc') {
            imocImage = block?.image;
            imocOfferSection = block?.offer_section;
            layout = block?.layout || '';
            header = block?.header || '';
            headerColor = block?.header_color?.color;
            subtextHeader = block?.sub_header_text || null;
            imocCopy = block?.copy || '';
            imocCopyColor = block?.copy_color?.color;
            imocReadMoreCopy = block?.read_more_copy || null;
            imocShowSameDay = block?.show_same_day_cutoff_times || null;
            imocBackgroundColor = block?.background_color;
            countDownColors = block?.count_down_colors;
            fontSize = block?.font_size;
            languageAttribute = (block?.language_attribute && block.language_attribute !== 'en') ? block.language_attribute : '';
        }
    });

    const classes = useStyles({
        imocBackgroundColor,
        headerColor,
        imocCopyColor,
    });

    let hdImocCopy = classes.topImocCopyContainer;
    if (imocImage) {
        hdImocCopy = `${classes?.topImocCopyContainer} ${classes.imocBorderNone}`;
    }
    const isNoOfferAvailable = layout === 'no offer' || !layout;

    let imocCopyMainContainer = classes.imocCopyContainer;
    if (typeof window !== 'undefined' && window.location.href.split('-').indexOf('bgs') >= 0) {
        imocCopyMainContainer = `${classes.bgsImocContainer} ${classes.imocCopyContainer}`;
    }

    const imocWrapperWithCopyCss = presentationFamily === 'flower' ? classes.imocWrapperFlower : '';

    let bannerStyles = `${classes.imocWrapper} ${imocWrapperWithCopyCss}`;
    let headerStyles = classes.header;

    if (!imocCopy) {
        headerStyles = classes.noImocCopyStyling;
        bannerStyles = `${classes.imocWrapper} ${classes.centerHero}`;
    }
    if (presentationFamily === 'food') {
        headerStyles = classes.headerImocFood;
    }

    let bannerBackgroundStyles;
    if (imocBackgroundColor) {
        bannerBackgroundStyles = classes.imocWrapperBgColor;
    }

    const handleMarkDownCopyWithChatFilter = (children) => {
        if (typeof children === 'string') {
            if (typeof children === 'string' && children?.toLowerCase()?.includes('chat')) {
                return <RenderChatAsLink value={children} operatingSystem={operatingSystem} currentBrand={currentBrand} featureFlags={featureFlags} customerData={customerData} />;
            }
            return transformCharacters(children);
        }
        return children;
    };
    return (
        <>
            <div data-testid="desktop-imoc" className={`${classes.desktopImocContainer} ${!isNoOfferAvailable ? classes.imocOfferContainer : ''}`}>
                {imocShowSameDay && !isSameDayCollectionV2 && <CountDownTimerBanner samedayCutOff={samedayCutOff} countDownColors={countDownColors} imocBanner />}
                <div
                    id="imoc-container"
                    role="region"
                    aria-label="ImocBanner"
                    className={`${bannerStyles} ${bannerBackgroundStyles}`}
                >
                    {imocImage ? (
                        // 8868; img alt left empty
                        <img src={imocImage?.url} className={classes.imgLogo} alt="" />
                    ) : (
                        <div className={`${presentationFamily !== 'food' ? classes.headerWrapper : ''} ${classes.h1}`} lang={languageAttribute}>

                            {header && (
                                <h1
                                    className={headerStyles}
                                >{transformCharacters(header)}
                                </h1>
                            )}
                            {subtextHeader && (
                                <>
                                    <ReactMarkdown
                                        source={subtextHeader}
                                        skipHtml
                                        className={classes.subtextHeader}
                                    />
                                </>
                            )}
                        </div>
                    )}

                    {layout === 'double offer' ? (
                        <div className={classes.doubleOfferContainer}>
                            {imocOfferSection?.offer_copy?.map((offer) => (
                                <ReactMarkdown
                                    renderers={transformRenderer}
                                    source={offer}
                                    skipHtml
                                    className={classes.doubleOffer}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className={(brand === 'harryanddavid') ? hdImocCopy : imocCopyMainContainer}>
                            {/* 8868; keeping old jsx as css class 'imocCopy' is applied on various levels */}
                            {fontSize
                                ? (
                                    <div
                                        className={(brand === 'harryanddavid') ? classes.imocCopy : classes.copy}
                                        style={{ fontSize }}
                                        data-testid="imocCopyDesktop"
                                    >
                                        <ReactMarkdown
                                            renderers={{
                                                ...transformRenderer,
                                                text: ({ children }) => handleMarkDownCopyWithChatFilter(children),
                                            }}
                                            source={imocCopy}
                                            escapeHtml={false}
                                            className={classes.imocCopy}
                                        />
                                    </div>
                                ) : (
                                    <ReactMarkdown
                                        renderers={{
                                            ...transformRenderer,
                                            text: ({ children }) => handleMarkDownCopyWithChatFilter(children),
                                            paragraph: ({ children }) => (languageAttribute ? <p><span lang={languageAttribute}>{children}</span></p> : <p>{children}</p>),
                                        }}
                                        source={imocCopy}
                                        escapeHtml={false}
                                        className={(brand === 'harryanddavid') ? classes.imocCopy : classes.copy}
                                    />
                                )}
                            {imocReadMoreCopy
                                ? (
                                    <>
                                        <ReactMarkdown
                                            renderers={{
                                                ...transformRenderer,
                                                text: ({ children }) => handleMarkDownCopyWithChatFilter(children),
                                            }}
                                            source={imocReadMoreCopy}
                                            skipHtml
                                            className={isReadMoreVisible ? `${classes.copy} ${classes.readMoreCopy}` : `${classes.displayNone}`}
                                        />
                                        <button type="button" className={classes.readMore} onClick={handleClick}>
                                            {isReadMoreVisible ? 'Close' : 'Read more'}
                                        </button>
                                    </>
                                ) : null}
                            {imocShowSameDay && !isSameDayCollectionV2 && <DeliveryTable samedayCutOff={samedayCutOff} />}
                        </div>
                    )}

                    {isNoOfferAvailable ? null : <a className={classes.offer} href="#disclaimer">*Offer Details</a>}

                </div>
            </div>
        </>
    );
};

DesktopTabletImoc.propTypes = {
    imocBlock: arrayOf(
        shape({
            layout: string,
            header: string.isRequired,
            copy: string.isRequired,
            read_more_copy: string,
            image: object,
            offer_section: object,
            font_color: object,
            font_size: number,
        }),
    ).isRequired,
    brand: string.isRequired,
    presentationFamily: string,
    samedayCutOff: shape({
        entries: arrayOf(shape({
            cutoff_entries: array,
        })),
    }),
    isSameDayCollectionV2: bool,
};

DesktopTabletImoc.defaultProps = {
    samedayCutOff: {},
    presentationFamily: '',
    isSameDayCollectionV2: false,
};

const mapStateToProps = (state) => ({
    presentationFamily: getPresentationFamily(state),
});

export default connect(mapStateToProps, null)(DesktopTabletImoc);
