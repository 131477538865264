/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import { makeStyles } from '@material-ui/core/styles';
import { intervalToDuration, compareAsc } from 'date-fns';

import {
    arrayOf, bool, object, shape, string,
} from 'prop-types';
import { AMPMToMilitary } from '../../../../../../../../../helpers/getNextNDates';
import CUSTOM_BREAKPOINTS from '../../../../../../../../../helpers/customBreakpoints';
import CountDownTimerBannerV2 from './CountDownTimerBannerV2';

const useStyles = makeStyles((theme) => ({
    fullContainer: (countDownColors) => ({
        background: countDownColors?.count_down_background_color || '#ffdc00',
        alignItems: 'center',
        justifyContent: 'center',
        color: countDownColors?.count_down_text_color || '#000000',
        '& + #imoc-container': {
            padding: '0 0 10px 0',
        },
    }),
    countDownText: {
        fontWeight: 'bold',
        [theme.breakpoints.down(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            fontSize: '16px',
        },
    },
    watchLaterContainer: {
        display: 'flex',
        padding: '5px 0',
    },
    guaranteedText: {
        [theme.breakpoints.down(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            fontSize: '12px',
            lineHeight: '1.1',
        },
    },
    orderInTheNextContainer: {
        marginLeft: '3%',
        [theme.breakpoints.down(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            width: '15%',
        },
    },
    countDownContainer: {
        marginLeft: '3%',
    },
    guaranteedContainer: {
        marginLeft: '3%',
        [theme.breakpoints.down(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            width: '30%',
            marginLeft: '5%',
        },
    },
    orderInTheNextText: {
        [theme.breakpoints.down(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            fontSize: '12px',
            lineHeight: '1.1',
        },
    },
}));

const calculateTimeLeft = (cutOffTimeForToday) => {
    const now = new Date();
    try {
        if (compareAsc(cutOffTimeForToday, now) === 1) {
            return intervalToDuration({
                start: now,
                end: cutOffTimeForToday,
            });
        }
        return false;
    } catch {
        return null;
    }
};

// Does given var have a value?  return true/false
// Unlike a regular js if, this function will return true if v's value is zero
const hasValue = (v) => (typeof v !== 'undefined' && v !== null);

const generateCutoffDate = (cutoffTimes) => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    let cutOffHour;
    if (dayOfWeek === 6) {  // saturday
        cutOffHour = hasValue(cutoffTimes?.[1].time) ? AMPMToMilitary(cutoffTimes[1].time) : 1300; // 1:00PM;
    } else if (dayOfWeek === 0) {  // sunday
        cutOffHour = hasValue(cutoffTimes?.[2].time) ? AMPMToMilitary(cutoffTimes[2].time) : 1130; // 11:30AM;
    } else {  // monday-friday
        cutOffHour = hasValue(cutoffTimes?.[0].time) ? AMPMToMilitary(cutoffTimes[0].time) : 1430; // 2:30PM default cutoff time;
    }
    const cutOffTimeToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), Number(cutOffHour.toString().substring(0, 2)), Number(cutOffHour.toString().substring(2, 4)));
    return cutOffTimeToday;
};

const CountDownTimerBanner = ({
    samedayCutOff, countDownColors, isSameDayCollectionV2, sameDayBlock, imocBanner,
}) => {
    const classes = useStyles(countDownColors);
    const cutOffTimesForAllDays = samedayCutOff?.entries?.[0]?.cutoff_entries?.find((typeOfDate) => typeOfDate?.type === 'Residential')?.day_and_time;
    const cutOffTimeForToday = useMemo(() => generateCutoffDate(cutOffTimesForAllDays), [cutOffTimesForAllDays]);
    const [timeToCutOff, setTimeToCutOff] = useState(calculateTimeLeft(cutOffTimeForToday));
    useEffect(() => {
        if (compareAsc(new Date(), cutOffTimeForToday) === -1) {
            setTimeout(() => {
                setTimeToCutOff(calculateTimeLeft(cutOffTimeForToday));
            }, 1000);
        }
    });

    if (isSameDayCollectionV2 && !imocBanner) {
        return (
            <CountDownTimerBannerV2 timeToCutOff={timeToCutOff} samedayCutOff={samedayCutOff} sameDayBlock={sameDayBlock} />
        );
    }
    if (timeToCutOff && imocBanner) {
        return (
            <Grid container direction="row" className={classes.fullContainer}>
                <Grid item className={classes.watchLaterContainer}>
                    <WatchLaterIcon className={classes.watchLaterIcon} />
                </Grid>
                <Grid item className={classes.orderInTheNextContainer}>
                    <Typography className={classes.orderInTheNextText}>
                        Order in the next
                    </Typography>
                </Grid>
                <Grid item className={classes.countDownContainer}>
                    <Typography style={timeToCutOff.hours === 0 ? { color: 'red' } : null} className={classes.countDownText}>
                        {`${timeToCutOff.hours}h ${timeToCutOff.minutes}m ${timeToCutOff.seconds}s`}
                    </Typography>
                </Grid>
                <Grid item className={classes.guaranteedContainer}>
                    <Typography className={classes.guaranteedText}>
                        for GUARANTEED delivery Today!
                    </Typography>
                </Grid>
            </Grid>

        );
    }
    return null;
};

CountDownTimerBanner.propTypes = {
    countDownColors: shape({
        count_down_background_color: string.isRequired,
        count_down_text_color: string.isRequired,
    }).isRequired,
    samedayCutOff: shape({
        entries: arrayOf(
            shape({
                cutoff_entries: arrayOf(
                    shape({
                        type: string.isRequired,
                        day_and_time: arrayOf(
                            shape({
                                select: arrayOf(
                                    string,
                                ).isRequired,
                                time: string.isRequired,
                                title: string.isRequired,
                            }),
                        ).isRequired,
                    }).isRequired,
                ).isRequired,
            }).isRequired,
        ).isRequired,
    }).isRequired,
    isSameDayCollectionV2: bool,
    sameDayBlock: object,
    imocBanner: bool,
};
CountDownTimerBanner.defaultProps = {
    isSameDayCollectionV2: false,
    sameDayBlock: {},
    imocBanner: false,
};

export default CountDownTimerBanner;
