/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { object } from 'prop-types';

const styles = (theme) => ({
    imocDeliveryTableV2: {
        width: '100%',
        textAlign: 'center',
        fontSize: '16px',
        lineHeight: '24px',
        background: theme.palette.cms?.white || '#ffffff',
        padding: '7px 13px 18px 18px',
        borderBottomRightRadius: '8px',
        borderBottomLeftRadius: '8px',
        '& th, & td': {
            padding: '4px 35px',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 'normal',
            '@media only screen and (max-width: 768px) ': {
                padding: '8px 5px',
                minWidth: '75px',
            },
            '@media only screen and (max-width: 400px) ': {
                minWidth: 'auto',
            },
            '& th:last-child, & td:last-child': {
                paddingRight: '0px',
            },
        },
        '& tr': {
            '& td:first-child': {
                textAlign: 'left',
                paddingLeft: '0px',
                '@media only screen and (max-width: 768px) ': {
                    paddingLeft: '12px',
                },
            },
        },
        '@media only screen and (max-width: 768px) ': {
            fontSize: '14px',
            lineHeight: '20px',
            padding: '0px',
        },
        '& th': {
            '@media only screen and (max-width: 768px) ': {
                background: theme.palette.cms?.white || '#fff',
            },
        },
    },
    tableContainer: {
        maxWidth: '810px',
        width: '100%',
        margin: '0 auto',
        '@media only screen and (max-width: 768px) ': {
            background: theme.palette.cms?.white || '#ffffff',
            borderRadius: '8px',
        },
    },
    header: {
        fontSize: '18px',
        lineHeight: '24px',
        display: 'block',

        '@media only screen and (max-width: 768px) ': {
            fontSize: '16px',
        },
    },
    subHeader: {
        fontSize: '14px',
        lineHeight: '20px',
        display: 'block',
        '@media only screen and (max-width: 768px) ': {
            fontSize: '12px',
            lineHeight: '18px',
        },
    },
    headerContainer: {
        background: theme.palette.cms?.lightGray || '#F7F7F7',
        padding: '13px 0px',
        textAlign: 'center',
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
        '@media only screen and (max-width: 768px) ': {
            background: theme.palette.cms?.lightGray || '#F7F7F7',
            padding: '14px 18px',
        },
    },
    dayTitle: {
        fontFamily: 'LatoBold',
    },
});

const DeliveryTableV2 = ({
    classes, samedayCutOff, sameDayBlock,
}) => {
    const cutOffTimes = samedayCutOff?.entries?.[0]?.cutoff_entries;
    if (cutOffTimes && cutOffTimes.length > 0) {
        return (
            <div className={classes.tableContainer}>
                <div className={classes.headerContainer}>
                    <span className={classes.header}>{sameDayBlock?.table_heading || 'Same day delivery deadlines (in recipient’s time zone)'}</span>
                    <span className={classes.subHeader}>{sameDayBlock?.table_sub_heading || '*Times are subject to change. Extended same-day delivery available in select local markets.'}</span>
                </div>
                <table className={classes.imocDeliveryTableV2} summary="Same-Day Delivery">
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            {cutOffTimes?.map((data) => (
                                <th className={classes.dayTitle}>{data?.type}</th>
                            ))}
                        </tr>

                    </thead>
                    <tbody>
                        {['Sunday', 'Monday to Friday', 'Saturday'].map((day) => (
                            <tr key={day}>
                                <td className={classes.dayTitle}>{day === 'Monday to Friday' ? 'Weekdays' : day}</td>
                                {cutOffTimes.map((data) => {
                                    const time = data.day_and_time.find((d) => d.title === day);
                                    return <td key={data._metadata.uid}>{time ? time.time : '-'}</td>;
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
    return null;
};

DeliveryTableV2.propTypes = {
    classes: object.isRequired,
    samedayCutOff: object,
    sameDayBlock: object,
};

DeliveryTableV2.defaultProps = {
    samedayCutOff: {},
    sameDayBlock: {},
};

export default withStyles(styles)(DeliveryTableV2);
