/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { object } from 'prop-types';

const styles = () => ({
    imocDeliveryTable: {
        width: '100%',
        borderSpacing: 0,
        textAlign: 'center',
        fontSize: '14px',
        lineHeight: '16px',
        marginTop: '5px',
        tableLayout: 'fixed',
        '& th, & td': {
            padding: '3px 0',
        },
        '& th': {
            fontWeight: 'bold',
        },
        '& tr': {
            '& td:first-child': {
                textAlign: 'left',
                fontWeight: 'bold',
                paddingLeft: '5px',
            },
        },
        '& tbody': {
            '& tr': {
                '&:nth-child(odd)': {
                    backgroundColor: '#F4F1F8', // theme/brand pallette eventually
                },
            },
        },
    },
});

const DeliveryTable = ({ classes, samedayCutOff }) => {
    const cutoffTimes = samedayCutOff?.entries?.[0]?.cutoff_entries;
    if (cutoffTimes && cutoffTimes.length > 0) {
        return (
            <table className={classes.imocDeliveryTable} summary="Same-Day Delivery">
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Monday to Friday</th>
                        <th>Saturday</th>
                        <th>Sunday</th>
                    </tr>
                </thead>
                <tbody>
                    {cutoffTimes?.map((data) => (
                        <tr>
                            <td>{data?.type}</td>
                            {data?.day_and_time?.map((d) => (
                                <td>{d?.time}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
    return null;
};

DeliveryTable.propTypes = {
    classes: object.isRequired,
    samedayCutOff: object,
};

DeliveryTable.defaultProps = {
    samedayCutOff: {},
};

export default withStyles(styles)(DeliveryTable);
